import React from "react";
import Layout from "@organisms/Layout";
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql, withPrefix } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ProductImageTitleDesc from "@molecules/ProductImageTitleDesc";
import Header2 from "@atoms/Header2";
import { advantages } from "../constants";
import AdvantageCard from "@molecules/AdvantageCard";

const ProductsPage = () => {
  const { t } = useTranslation("products");

  console.log(t);

  return (
    <Layout>
      <Helmet>
        <title>BARAKA | {t("Produkte")}</title>
      </Helmet>

      <main>
        <div
          style={{
            backgroundSize: "cover",
            backgroundPositionY: "center",
            backgroundPositionX: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${withPrefix(
              "/images/products-main-pic.jpg"
            )})`,
          }}
          className="relative h-screen w-full flex flex-col justify-center items-center mb-7"
        >
          <div className="z-10 text-center">
            <h1 className="text-center text-white mb-3">
              {t("Wir stehen für")}
              <br /> {t("hochqualitative Produkte")}
            </h1>
          </div>
          <div className="absolute w-full h-full bg-black opacity-20" />
        </div>
        <div className="mb-10">
          <Header2 content={t("Unsere Produkte")} />
        </div>
        <ProductImageTitleDesc
          title={t("Hartweizengrieß")}
          descLargeText={t("Hartweizengrieß-Desc-Large")}
          descSmallText={t("Hartweizengrieß-Desc-Small")}
          img={
            <StaticImage
              imgStyle={{ backgroundColor: "#EDEDED" }}
              className="py-10 rounded-xl"
              objectFit="contain"
              src="../assets/images/hwg.png"
              alt="Baraka Hartweizengriess Image Transparent"
            />
          }
        />
        <ProductImageTitleDesc
          title={t("Couscous")}
          descLargeText={t("Couscous-Desc-Large")}
          descSmallText={t("Couscous-Desc-Small")}
          img={
            <StaticImage
              imgStyle={{ backgroundColor: "#EDEDED" }}
              className="py-10 rounded-xl"
              objectFit="contain"
              src="../assets/images/hartweizengriess.png"
              alt="Baraka Couscous Image Transparent"
            />
          }
        />

        <div className="mb-20">
          <ProductImageTitleDesc
            title={t("Bulgur")}
            descLargeText={t("Bulgur-Desc-Large")}
            descSmallText={t("Bulgur-Desc-Small")}
            img={
              <StaticImage
                imgStyle={{ backgroundColor: "#EDEDED" }}
                className="py-10 rounded-xl"
                objectFit="contain"
                src="../assets/images/bulgur.png"
                alt="Baraka Bulgur Image Transparent"
              />
            }
          />
        </div>

        <div className="mb-5">
          <Header2 content="Made In Germany" />
        </div>

        <div className="flex flex-col items-center md:flex-row md:flex-wrap md:justify-center mb-10">
          {advantages.map(({ advantages, title }, index) => {
            return (
              <AdvantageCard
                isLeft={index % 2 == 0}
                key={title}
                title={title}
                checkTexts={advantages}
              />
            );
          })}
        </div>
      </main>
    </Layout>
  );
};

export default ProductsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["header", "footer", "products", "about-us"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
