import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

type Props = {
  isActive?: boolean;
  href?: string;
  onClick?: () => void;
};

const RoundedLink: React.FC<Props> = ({
  children,
  isActive = false,
  href,
  onClick,
}) => {
  if (href) {
    return (
      <Link
        onClick={onClick}
        style={{
          backgroundColor: isActive ? "#F7A600" : "transparent",
          color: isActive ? "white" : "black",
          border: isActive ? "1.5px solid #F7A600" : "1.5px solid black",
          paddingTop: "4px",
          paddingBottom: "6px",
        }}
        to={href}
        className={`block text-center w-full px-8 rounded-3xl whitespace-nowrap`}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      onClick={onClick}
      className={`block text-center w-full px-8 rounded-3xl whitespace-nowrap font-bold`}
      style={{
        backgroundColor: isActive ? "#F7A600" : "transparent",
        color: isActive ? "white" : "black",
        border: isActive ? "1.5px solid #F7A600" : "1.5px solid black",
        paddingTop: "4px",
        paddingBottom: "6px",
      }}
    >
      {children}
    </button>
  );
};

export default RoundedLink;
