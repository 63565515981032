import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import { DeviceType } from "types";

type Props = {
  title: string;
  checkTexts: string[];
  isLeft: boolean;
};

const AdvantageCard: React.FC<Props> = ({ checkTexts, title, isLeft }) => {
  const { t } = useTranslation("about-us");

  let margin = "mr-auto";
  if (isLeft) margin = "ml-auto";

  return (
    <div
      style={{
        height: 550,
        maxWidth: 400,
      }}
      className="px-5 py-5 w-full h-full md:w-1/2 xl:w-1/3"
    >
      <div
        style={{
          backgroundColor: "#FFF7E7",
        }}
        className="h-full px-5 py-5 md:px-10 md:py-10 rounded-xl "
      >
        <div className="flex">
          <div className="w-20 mb-10">
            <StaticImage
              objectFit="contain"
              alt="Baraka Logo Transparent"
              src="../../assets/images/baraka-logo.png"
              className="mr-4"
            />
          </div>

          <p className="font-bold flex-1">{t(title)}</p>
        </div>

        <div>
          {checkTexts.map((checkText) => {
            return (
              <div className="flex items-center mb-4">
                <div className="mr-4">
                  <FaCheckCircle fill="green" className="text-lg" />
                </div>
                <p className="leading-5">{t(checkText)}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdvantageCard;
