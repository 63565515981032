import { AdvantageCardDataType } from "types";

export const advantages: AdvantageCardDataType[] = [
  {
    title: "Modernste Reinigungstechnik für Ihre Produktsicherheit",
    advantages: [
      "„STEINFREI“ durch moderne Röntgentechnologie",
      "Metalldetektor für Eisen- und Nichteisenmetalle",
      "Optischer Farbdetektor „SORTEX“ zum Aussortieren von Fremdbestandteilen",
    ],
  },
  {
    title: "Qualitätsversprechen unseres Lieferanten: Deutsche Standards",
    advantages: [
      "IFS 6 Zertifizierung",
      "BIO-Zertifizierung",
      "VLOG-Zertifizierung",
      "Kosher-Zertifizierung",
      "Prozesszertifizierung nach GMP + B2",
      "Energiemanagementsystem nach DIN ISO 50001",
    ],
  },
  {
    title: "So wie Sie es benötigen: Flexible Gebindeformate",
    advantages: [
      "Modernste Abfüll- und Absacktechnik",
      "Sackware 5kg, 10kg, 25kg",
      "BigBags 500 – 1.000kg",
      "Silo-LKW 25 to",
      "Starke Partner für Kleinpackungen",
    ],
  },
  {
    title: "Getreide Know-how: Beste Rohstoffe",
    advantages: [
      "Permanente Qualitätsprüfung sorgt für gleichbleibende Produktqualitäten",
      "Langjährige Partnerschaften mit unseren Lieferanten",
      "Nachhaltiger Vertragsanbau möglich",
      "Warenbeschaffung über LKW, Schiff und Bahn",
    ],
  },
  {
    title: "Kurzfristig Lieferbar: Kontinuierliche Warenverfügbarkeit",
    advantages: [
      "Kurze Lieferwege zum Kunden durch zentrale Lage und optimale Verkehrsanbindung",
      "Flexible Kommunikation mit Logistikpartnern",
      "Kürzeste Wege innerhalb der Produktion",
      "Keine Anfahrten aus EU und Drittländern",
      "Keine „Zollfenster“ bei Bulgur in der Belieferung",
    ],
  },
  {
    title: "Keine langen Lagerzeiten: Frische der Produkte",
    advantages: [
      "Vermahlung von Hartweizengrieß direkt in Deutschland",
      "Keine Engpässe in der Rohwarenversorgung durch eigene Hartweizenmühle unseres Partners",
      ,
    ],
  },
];
