import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "../../assets/images/weizen.png";
type Props = {
  content: string;
};

const Header2: React.FC<Props> = ({ content }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <h2 className="py-3 text-4xl">{content}</h2>

      <div className="flex items-center">
        <div
          style={{
            backgroundColor: "#f7a600",
          }}
          className="h-1 w-20"
        />
        <div className="px-4">
          <StaticImage
            alt="Weizen Bild Baraka"
            src="../../assets/images/weizen.png"
            placeholder="none"
            height={50}
          />
        </div>
        <div
          style={{
            backgroundColor: "#f7a600",
          }}
          className="h-1 w-20"
        />
      </div>
    </div>
  );
};

export default Header2;
