import RoundedLink from "@atoms/rounded_link/RoundedLink";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title?: string;
  descSmallText?: string;
  descLargeText?: string;
  img?: any;
};

const ProductImageTitleDesc: React.FC<Props> = ({
  descLargeText,
  descSmallText,
  img,
  title,
}) => {
  const { t } = useTranslation("products");
  const [showSmallDescription, setSmallDescriptionVisibility] =
    useState<boolean>(true);

  return (
    <div
      style={{
        maxWidth: 1200,
        backgroundColor: "#FFF7E7",
      }}
      className="mx-2 text-center md:text-left flex flex-col-reverse md:flex-row md:items-center md:mx-auto rounded-xl p-10 my-5"
    >
      <div className="flex-1 md:pr-10">
        {showSmallDescription && (
          <div
            style={{ backgroundColor: "#f7a600" }}
            className="block md:hidden h-1 w-full mb-4"
          />
        )}

        <h2 className="text-black font-bold mb-4 md:mb-10">{title}</h2>
        <div className="mb-4 md:mb-10">
          {showSmallDescription && descSmallText}
          {!showSmallDescription && descLargeText}
        </div>
        <div
          style={{ backgroundColor: "#f7a600" }}
          className="hidden md:block md:h-1 w-2/3 mb-4 md:mb-4"
        />
        <div style={{ maxWidth: 200 }} className="mx-auto md:m-0">
          <RoundedLink
            isActive
            onClick={() => setSmallDescriptionVisibility((t) => !t)}
          >
            {showSmallDescription && t("Mehr lesen")}
            {!showSmallDescription && t("Weniger lesen")}
          </RoundedLink>
        </div>
      </div>
      {showSmallDescription && (
        <div className="mb-10 md:mb-0 md:w-2/5">{img}</div>
      )}
    </div>
  );
};
export default ProductImageTitleDesc;
